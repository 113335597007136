import { useGateValue } from '@statsig/react-bindings'

const useFeatureFlag = (flag) => {
  const value = useGateValue(flag)
  return {
    value
  }
}

export default useFeatureFlag
