const extractAssetDownloadUrl = (url) => {
  const regex = /https?:\/\/[^/]+\/asset\/([^/]+)\/user\/([^/]+)\/([^/]+)/

  const match = url?.match(regex)

  if (match) {
    const serviceName = match[1]
    const userId = match[2]
    const assetId = match[3]

    return {
      serviceName,
      userId,
      assetId
    }
  } else {
    return null
  }
}

export default extractAssetDownloadUrl
