import 'intersection-observer'
import useFetchAssetUrl from '../../hooks/useFetchAssetUrl'
import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import LoadingIcon from '../icons/LoadingIcon'

const SecureS3Image = ({
  src,
  fallbackURL,
  className,
  width,
  height,
  dataTestid,
  alt,
  isPriority = false,
  loadingIconSize = 5,
  ...rest
}) => {
  const fetchAssetPresignedUrl = useFetchAssetUrl()
  const [imageUrl, setImageUrl] = useState(fallbackURL)
  const [isLoading, setLoading] = useState(true)
  const imageRef = useRef(null)
  const observerRef = useRef(null)

  const loadImage = async () => {
    try {
      setLoading(true)
      const { url } = await fetchAssetPresignedUrl(src)
      setImageUrl(url)
    } catch (error) {
      setImageUrl(fallbackURL)
    }
  }

  useEffect(() => {
    if (isPriority) {
      loadImage()
    } else {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadImage()
            observerRef.current?.disconnect()
          }
        },
        { rootMargin: '50px' }
      )

      if (imageRef.current) {
        observerRef.current.observe(imageRef.current)
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [src])

  const widthStyle = width ? { width } : {}
  const heightStyle = height ? { height } : {}
  const style = {
    ...widthStyle,
    ...heightStyle
  }

  return (
    <div className={classNames('flex w-full h-full align-center justify-center relative')}>
      {isLoading && (
        <LoadingIcon
          className={`absolute h-${loadingIconSize} w-${loadingIconSize} !ml-auto top-0 bottom-0 left-0 right-0 m-auto`}
        />
      )}
      <img
        ref={imageRef}
        src={imageUrl}
        className={classNames(className, {
          'opacity-0': isLoading,
          'opacity-100': !isLoading
        })}
        style={style}
        data-testid={dataTestid}
        alt={alt}
        loading={isPriority ? 'eager' : 'lazy'}
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        {...rest}
      />
    </div>
  )
}

export default SecureS3Image
