import classNames from '../../../utils/text/classnames'
import SecureS3Image from '../../assets/SecureS3Image'

const sizes = {
  xxs: 'h-[18px] w-[18px]',
  xs: 'h-6 w-6',
  sm: 'h-8 w-8',
  md: 'h-10 w-10',
  lg: 'h-12 w-12',
  xl: 'h-14 w-14',
  xxl: 'w-32 h-32',
  full: 'w-full h-full',
  custom28px: 'h-7 w-7'
}

export default function AvatarImageSecure({ size = 'sm', imageUrl, fallbackURL, className, ...rest }) {
  const sizeClassNames = sizes[size]
  return (
    <SecureS3Image
      className={classNames(sizeClassNames, 'inline-block rounded-full object-cover flex-shrink-0', className)}
      src={imageUrl}
      alt="Profile Avatar"
      dataTestid="avatar-image"
      {...rest}
    />
  )
}
