import extractAssetDownloadUrl from '../utils/extractAssetDownloadUrl'
import { API_SERVICES, CONFIG } from '../utils/constants'
import useGetToken from './useGetToken'

const useFetchAssetUrl = () => {
  const getToken = useGetToken()
  return async (downloadURL) => {
    const token = await getToken()

    const downloadURLInfo = extractAssetDownloadUrl(downloadURL)
    if (!downloadURLInfo) {
      throw new Error('Download URL is not valid')
    }
    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.ASSET}/url/${downloadURLInfo.serviceName}/user/${downloadURLInfo.userId}/${downloadURLInfo.assetId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (!response.ok) throw new Error()

    const jsonResponse = await response.json()
    let url = jsonResponse.url
    const expiresAt = jsonResponse.expiresAt
    // Change localstack hostname for local environment
    if (CONFIG.IS_LOCAL) {
      url = url?.replace('localstack:4566', 'localhost:4566')
    }

    return { url, expiresAt }
  }
}

export default useFetchAssetUrl
