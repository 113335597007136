import { StatsigProvider } from '@statsig/react-bindings'
import { STATSIG_CLIENT_SDK_KEY, CONFIG } from '../utils/constants'
import { useAuth } from './AuthProvider'

export const getEnvName = (environmentName = CONFIG.ENV_NAME) => {
  switch (environmentName) {
    case 'local': {
      return 'development'
    }
    default: {
      return environmentName
    }
  }
}

const FeatureFlagProvider = ({ children }) => {
  const auth = useAuth()

  const formattedUserData = {
    organizations: auth?.user?.organizations,
    workspaces: auth?.user?.workspaces,
    uid: auth?.user?.uid,
    onboarded: auth?.user?.onboarded,
    emailVerified: auth?.user?.emailVerified
  }

  return (
    <StatsigProvider
      sdkKey={STATSIG_CLIENT_SDK_KEY}
      waitForInitialization={true}
      user={{ privateAttributes: formattedUserData, userID: auth?.user?.uid, email: auth?.user?.email }}
      options={{
        environment: { tier: getEnvName() }
      }}
    >
      {children}
    </StatsigProvider>
  )
}

export default FeatureFlagProvider
